export const getNavOptions = (hideTools = [], tagName = "", allowTrigger) => {
  let navOptions = [
    {
      label: "None",
      value: "",
    },
    {
      label: "Next Trigger",
      value: "nextTrigger",
      type: "next",
    },
    {
      label: "Previous Trigger",
      value: "prevTrigger",
      type: "previous",
    },
    {
      label: "Web Address",
      value: "webAddress",
      placeholder: "https://",
      showOpenInNewTab: true,
    },
    {
      label: "Select Page and Section",
      value: "page",
      placeholder: "Select Page and Section",
      showOpenInNewTab: true,
    },
    {
      label: "Top/Bottom of page",
      value: "scrollTopOrBottom",
    },
    {
      label: "Email",
      value: "email",
      placeholder: "email",
    },
    {
      label: "Phone number",
      value: "phone",
      placeholder: "phone",
    },
  ];

  navOptions = navOptions.filter((n) => !hideTools.includes(n.value));

  if (!allowTrigger) {
    const triggerValues = ["nextTrigger", "prevTrigger"];
    navOptions = navOptions.filter((n) => !triggerValues.includes(n.value));
  }

  if (tagName !== "Pages") {
    navOptions = navOptions.filter((n) => n.value !== "page");
  }

  return navOptions;
};
