import React, { useState } from "react";
import { Node, Transforms } from "slate";
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { Box, IconButton, Tooltip } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { removeLink } from "../../utils/link";
import "./styles.css";
import { getLinkType, handleLinkType } from "../../utils/helper";
import LinkSettings from "../../common/LinkSettings";

const linkStyles = () => ({
  linkBtn: {
    border: "none",
    outline: "none",
    background: "none",
    fontSize: "inherit",
    fontWeight: "inherit",
    fontFamily: "inherit",
    color: "rgb(85, 26, 139)",
    textDecoration: "underline",
    padding: 0,
    margin: 0,

    "&:hover": {
      color: "rgb(65, 15, 110);",
    },
  },
});

const Link = (props) => {
  const { attributes, element, children, customProps } = props;
  const { readOnly } = customProps;
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  const [showInput, setShowInput] = useState(false);
  const [linkData, setLinkData] = useState({
    name: "",
    url: "",
    showInNewTab: true,
    linkType: "",
  });
  const path = ReactEditor.findPath(editor, element);
  const urlPath = element.url || element.href;
  const showInNewTab = element?.showInNewTab || element?.target;
  const linkType = element?.linkType;
  const classes = linkStyles();

  const updateLink = (data) => {
    Transforms.setNodes(editor, data, { at: path });
    setLinkData({
      name: "",
      url: "",
      showInNewTab: true,
      linkType: "",
    });
    setShowInput(false);
  };

  const onEditLink = () => {
    setLinkData({
      name: Node.string(element),
      href: urlPath || "",
      showInNewTab: showInNewTab,
      linkType: element?.linkType,
    });
    setShowInput(true);
  };

  const handleClose = () => {
    setShowInput(false);
  };

  const Toolbar = () => {
    const btnProps = handleLinkType(
      urlPath,
      linkType,
      true,
      showInNewTab === "_blank"
    );

    const navType = getLinkType(linkType, urlPath);

    const hideOpenLink = navType === "page" || !navType;

    return selected && focused ? (
      <div
        className="element-toolbar hr"
        contentEditable={false}
        style={{ width: "150px", top: "100%", left: "0px", display: "flex" }}
      >
        {hideOpenLink ? null : (
          <Tooltip title="Open">
            <Box
              sx={{
                display: "inline-flex",
                color: "rgba(0, 0, 0, 0.54)",
              }}
              {...btnProps}
            >
              <OpenInNewIcon />
            </Box>
          </Tooltip>
        )}

        <Tooltip title="Edit">
          <IconButton onClick={onEditLink}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove">
          <IconButton onClick={() => removeLink(editor)}>
            <LinkOffIcon />
          </IconButton>
        </Tooltip>
      </div>
    ) : null;
  };

  const buttonProps = handleLinkType(
    urlPath,
    linkType,
    readOnly,
    showInNewTab === "_blank"
  );

  return (
    <div className="link">
      <Box
        {...attributes}
        {...element.attr}
        sx={classes.linkBtn}
        {...buttonProps}
      >
        {children}
      </Box>
      <Toolbar />

      {showInput ? (
        <LinkSettings
          handleClose={handleClose}
          onSave={({ linkType, navValue, openInNewTab }) => {
            updateLink({
              linkType,
              url: navValue,
              target: openInNewTab ? "_blank" : "_self",
            });

            handleClose();
          }}
          navType={getLinkType(linkType, urlPath)}
          navValue={urlPath}
          openInNewTab={linkData.showInNewTab === "_blank"}
          customProps={customProps}
        />
      ) : null}
    </div>
  );
};

export default Link;
