/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useTheme,
  Popper,
} from "@mui/material";
import { Node, Path, Transforms } from "slate";
import { useSlateStatic, ReactEditor } from "slate-react";
import GridItemPopup from "./GridItemPopup";
import { IconButton, Tooltip, Box, Grid as Item } from "@mui/material";
import { GridSettingsIcon } from "../../common/iconslist";
import { useEditorContext, useEditorSelection } from "../../hooks/useMouseMove";
import {
  groupByBreakpoint,
  getBreakPointsValue,
  getTRBLBreakPoints,
} from "../../helper/theme";
import AddIcon from "@mui/icons-material/Add";
import { isEmptyNode } from "../../utils/helper";
import { onAddGridItem } from "../../utils/gridItem";
import useTableResize from "../../utils/customHooks/useTableResize";
import GridStyles from "./Styles";
import { useGrid, getChildCount } from "./Providers/GridProvider";

let TT = null;

const Resizer = ({ classes, onMouseDown, className }) => {
  return (
    <>
      <Box
        component={"div"}
        className={`col-width-resizer ${className}`}
        contentEditable={false}
        onPointerDown={onMouseDown}
        sx={classes.gridEqual}
        style={{ height: `100%` }}
      />
    </>
  );
};

const GridItemToolbar = (props) => {
  const { classes, selected, showTool, onSettings, onAddColumn, columnRef } =
    props;
  return selected && !showTool ? (
    <Popper
      anchorEl={columnRef?.current}
      open={true}
      placement="top-end"
      sx={classes.popTools}
      className="gi-tool-pp"
    >
      <Tooltip title="Column Settings" arrow>
        <IconButton size="small" onClick={onSettings}>
          <GridSettingsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add Column" arrow>
        <IconButton size="small" onClick={onAddColumn}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Popper>
  ) : null;
};

const GridItem = (props) => {
  const {
    columnWidths,
    widths,
    initColumnWidth,
    updateColumnWidth,
    updateColNodes,
  } = useGrid() || {};
  const { hoverPath, theme: appTheme } = useEditorContext();
  const columnRef = useRef(null);
  const theme = useTheme();
  const classes = GridStyles(theme, appTheme);
  const { attributes, children, element, customProps } = props;
  const { readOnly } = customProps;
  const [openSetttings, setOpenSettings] = useState(false);
  const {
    grid,
    bannerSpacing,
    bgColor,
    alignment,
    borderRadius,
    borderStyle,
    borderWidth,
    borderColor,
    margin,
    bgColorHover,
    borderColorHover,
    textColor,
    animation,
    cellGHeight,
    xsHidden,
    forceFullWidth,
    minWidth,
  } = element;
  const { vertical, horizantal, flexDirection } = alignment || {};
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const selected = hoverPath === path.join(",");
  const [showTool] = useEditorSelection(editor);
  const [parentDOM, setParentDOM] = useState({});
  const [alert, setAlert] = useState(null);
  const childCount = getChildCount(editor, path);
  const lastChild = childCount - 1 === [...path].pop();

  const [size, onMouseDown, resizing, onLoad, isDone] = useTableResize({
    ...parentDOM,
  });
  const isEmpty =
    !readOnly && isEmptyNode(editor, element?.children, path) ? "empty" : "";
  const cmWidth =
    resizing && size?.width && parentDOM?.gridWidth && widths?.left
      ? `${widths?.left}%`
      : minWidth
      ? `${minWidth}%`
      : "auto";
  // for right column width update
  const isRightCol =
    path?.join() === columnWidths?.rightPath && !resizing && widths?.right;
  const minWidthInPercent = isRightCol ? `${widths?.right}%` : cmWidth;

  useEffect(() => {
    try {
      if (
        columnRef?.current &&
        (!parentDOM?.parentDOM || !minWidth || lastChild)
      ) {
        initDoms(lastChild);
        if (lastChild && childCount <= 2) {
          Transforms.setNodes(editor, { minWidth: null }, { at: path });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [columnRef?.current, minWidth, childCount, lastChild]);

  useEffect(() => {
    if (isDone || isRightCol) {
      initDoms();
    }
  }, [minWidth, isDone]);

  useEffect(() => {
    if (!resizing && isDone) {
      updateColNodes(path);
    } else if (resizing) {
      // set init width
      initColumnWidth(path, (size?.width / parentDOM?.gridWidth) * 100);
    }
  }, [resizing, isDone]);

  useEffect(() => {
    if (resizing) {
      updateColumnWidth(
        {
          left: (size?.width / parentDOM?.gridWidth) * 100,
        },
        path
      );
    }
  }, [resizing, size]);

  const initDoms = () => {
    try {
      const gridNode = Node.get(editor, Path.parent(path));
      if (gridNode) {
        const gridDOM = ReactEditor.toDOMNode(editor, gridNode);
        setParentDOM({
          gridWidth: gridDOM?.offsetWidth,
          parentDOM: columnRef?.current,
          size: {
            width: columnRef?.current?.offsetWidth,
          },
        });
        onLoad({
          width: columnRef?.current?.offsetWidth,
          height: 100,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSettings = () => {
    setOpenSettings(true);
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: path,
      }
    );
    onClose();
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const onDelete = () => {
    if (path) {
      Transforms.removeNodes(editor, { at: path });
    }
  };

  const onAddColumn = () => {
    try {
      const gridPath = Path.parent(path);
      const gridNode = Node.get(editor, gridPath);
      if (gridNode?.children?.length === 5) {
        setAlert("Maximum column limit exceeded");
      } else {
        onAddGridItem({
          editor,
          element: gridNode,
          children: gridNode?.children || [],
          isColumn: gridNode?.equalItems,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setAlert(null);
  };

  const getBorderColor = () => {
    return borderColor || "transparent";
  };

  // getting Grid Item Width value
  const gridWidthBreakPointsValue = getBreakPointsValue(
    grid,
    null,
    "overrideGridSize",
    true
  );

  const getBRProps = groupByBreakpoint(
    {
      display: {
        xs: xsHidden ? "none" : "inline-block",
        lg: "inline-block",
      },
      width: {
        ...getBreakPointsValue(grid, null, "overrideGridSize", true),
        xs: forceFullWidth ? "100%" : gridWidthBreakPointsValue?.xs,
      },
      height: {
        ...getBreakPointsValue(cellGHeight || "auto"),
      },
      borderRadius: {
        ...getBreakPointsValue(
          borderRadius || {},
          null,
          "overrideBorderRadius",
          true
        ),
      },
      margin: {
        ...getTRBLBreakPoints(margin),
      },
    },
    theme
  );

  const giInProps = groupByBreakpoint(
    {
      padding: {
        ...getTRBLBreakPoints(bannerSpacing),
      },
    },
    theme
  );

  return (
    <Item
      item
      component={"div"}
      className={`grid-item element-root gi-top-wrpr dpath ${
        lastChild ? "lastChild" : ""
      }`}
      {...attributes}
      style={{
        textAlign: element.alignment || "left",
        "--minWidth": lastChild ? "auto" : minWidthInPercent,
      }}
      sx={{
        ...getBRProps,
        display: "flex",
        flexDirection: flexDirection || "column",
        background: bgColor || "transparent",
        borderColor: getBorderColor(),
        borderWidth: borderWidth || "1px",
        borderStyle: borderStyle || "solid",
        alignItems: horizantal,
        justifyContent: vertical,
        "&:hover": {
          background: `${bgColorHover || bgColor || "transparent"}`,
        },
      }}
      data-path={path.join(",")}
      data-info="handle-outside-editor-click"
    >
      {!readOnly && (
        <div
          className={`element-selector ${selected ? "selected" : ""}`}
          contentEditable={false}
          style={{ zIndex: 1000 }}
        >
          <div className="element-selector-dots tl"> </div>
          <div className="element-selector-dots tr"> </div>
          <div className="element-selector-dots bl"> </div>
          <div className="element-selector-dots br"> </div>

          <GridItemToolbar
            classes={classes}
            selected={selected}
            showTool={showTool}
            onSettings={onSettings}
            onAddColumn={onAddColumn}
            columnRef={columnRef}
          />
        </div>
      )}
      <Box
        ref={columnRef}
        className={`gi-inner-cw ${
          animation || ""
        } content-editable ${isEmpty} np`}
        component={"div"}
        data-path={path.join(",")}
        sx={{
          display: "flex",
          flexDirection: flexDirection || "column",
          ...giInProps,
          width: "100%",
          height: "100%",
          // dark theme default color effect - FS-6961
          color: textColor || "inherit",
          "&:hover": {
            borderColor: borderColorHover || borderColor || "transparent",
          },
        }}
        placeholder="Column"
        data-info="handle-outside-editor-click"
      >
        {children}
      </Box>
      {openSetttings ? (
        <GridItemPopup
          element={element}
          onSave={onSave}
          onClose={onClose}
          onDelete={onDelete}
          customProps={customProps}
        />
      ) : null}
      {!readOnly ? (
        <Resizer
          classes={classes}
          className={`${lastChild ? "last-resize" : ""} ${
            resizing ? "resizing" : ""
          }`}
          onMouseDown={onMouseDown}
          height={"auto"}
        />
      ) : null}
      <Dialog open={Boolean(alert)} onClose={handleClose}>
        <DialogContent>{alert}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Item>
  );
};

export default GridItem;
