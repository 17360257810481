import React, { useEffect, useState } from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import Slider from "react-slick";
import "./slick-theme.min.css";
import "./slick.min.css";
import { PrevArrow, NextArrow } from "./Arrows";
import { carouselItem } from "../../utils/carouselItem";
import { IconButton, Tooltip, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridAddSectionIcon } from "../../common/iconslist";

const Empty = ({ children }) => {
  return <div className="empty-carousel-wrapper">{children}</div>;
};

const Carousel = (props) => {
  const { attributes, children, element, customProps } = props;
  const { readOnly } = customProps;
  const editor = useSlateStatic();
  const [showOptions, setShowOptions] = useState(false);
  const [edit, setEdit] = useState(false);
  const [refresh, setRefresh] = useState(new Date().getTime());
  const path = ReactEditor.findPath(editor, element);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow className="slide-arrow-ei slider-next-ei" />,
    prevArrow: <PrevArrow className="slide-arrow-ei slider-prev-ei" />,
  };

  useEffect(() => {
    setRefresh(new Date().getTime());
  }, []);

  //comment this for carosul selection on insert 
  useEffect(() => {
    if (!edit) {
      ReactEditor.focus(editor);
      Transforms.select(editor, ReactEditor.findPath(editor, element));
      Transforms.deselect(editor);
    }
  }, [edit]);

  const onAddSlide = () => {
    try {
      Transforms.insertNodes(editor, [{ ...carouselItem() }], {
        at: [...path, children.length],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onEdit = () => {
    setEdit(!edit);
  };

  const onDelete = () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: [...path] });
  };

  const onMouseOver = () => {
    setShowOptions(true);
  };

  const onMouseLeave = () => {
    setShowOptions(false);
  };

  const ToolBar = () => {
    return !readOnly && showOptions ? (
      <div
        className="element-toolbar hr"
        contentEditable={false}
        style={{
          top: "-12px",
          left: "0px",
        }}
      >
        <Tooltip title={edit ? "Save Carousel" : "Edit Carousel"} arrow>
          <IconButton onClick={onEdit}>
            {!edit ? <EditIcon /> : <CheckCircleIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title={"Add Slide"} arrow>
          <IconButton onClick={onAddSlide}>
            <GridAddSectionIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Delete Carousel"} arrow>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    ) : null;
  };

  return (
    <div
      {...attributes}
      className={`sliderBg ${ edit ? "carousel_slider_edit" : ""}`}
      style={{
        backgroundColor: "transparent",
        position: "relative",
      }}
      contentEditable={edit}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {edit ? (
        <Empty>{children}</Empty>
      ) : (
        <Box
          component={"div"}
          style={{ padding: "12px" }}
          key={`carousel_${path.join("|")}_${refresh}`}
          sx={{
            '& .simple-text': {
              color: '#000'
            }
          }}
          contentEditable={false}
        >
          <Slider {...settings}>
            {children.map((m, i) => {
              return (
                <div key={i} className={`item-sc grid-container-toolbar-hide`}>
                  {m}
                </div>
              );
            })}
          </Slider>
        </Box>
      )}
      {!readOnly && <ToolBar />}
    </div>
  );
};

export default Carousel;
