const gridStyle = [
  {
    tab: "General",
    value: "general",
    fields: [
      {
        label: "Section Name",
        key: "id",
        type: "text",
        placeholder: "Id should be unique for the page...",
      },
    ],
  },
  {
    tab: "Colors",
    value: "colors",
    fields: [
      {
        label: "Background Color",
        key: "bgColor",
        type: "color",
      },
      {
        label: "Foreground Color",
        key: "fgColor",
        type: "color",
      },
      {
        label: "Border Color",
        key: "borderColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Background",
    value: "backgroundImage",
    fields: [
      {
        label: "Background Image URL",
        key: "backgroundImage",
        type: "text",
      },
      {
        label: "Background Image",
        key: "backgroundImage",
        type: "backgroundImage",
      },
    ],
  },
  {
    tab: "Position",
    value: "position",
    fields: [
      {
        label: "Hide on Mobile",
        key: "xsHidden",
        type: "selectBox",
        placeholder: "Hide on Mobile",
      },
      {
        label: "Set Postion (Vertical & Horizantal)",
        key: "alignment",
        type: "alignment",
      },
    ],
  },
  {
    tab: "Padding",
    value: "bannerSpacing",
    fields: [
      {
        label: "Banner Spacing",
        key: "bannerSpacing",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Border",
    value: "border",
    fields: [
      {
        label: "Border",
        key: "borderRadius",
        type: "borderRadius",
      },
      {
        label: "Border Width",
        key: "borderWidth",
        type: "text",
        placeholder: "1px",
        width: 6,
      },
      {
        label: "Border Style",
        key: "borderStyle",
        type: "textOptions",
        width: 6,
        options: [
          {
            text: "Solid",
            value: "solid",
          },
          {
            text: "Dotted",
            value: "dotted",
          },
          {
            text: "Dashed",
            value: "dashed",
          },
        ],
        renderOption: (option) => {
          return <span>{option.text}</span>;
        },
      },
    ],
  },
  {
    tab: "Size",
    value: "gridSize",
    fields: [
      // {
      //   label: "Width Size",
      //   key: "gridSize",
      //   type: "gridSize",
      // },
      {
        label: "Wrap",
        key: "flexWrap",
        type: "textOptions",
        width: 12,
        isBreakpoint: true,
        options: [
          {
            text: "Wrap",
            value: "wrap",
          },
          {
            text: "No Wrap",
            value: "nowrap",
          },
        ],
        renderOption: (option) => {
          return <span>{option.text}</span>;
        },
      },
    ],
  },
  {
    tab: "Grid Item Size",
    value: "equalItems",
    fields: [
      {
        label: "Equal Column Size",
        key: "equalItems",
        type: "selectBox",
        placeholder: "Hide on Mobile",
      },
    ],
  },
  {
    tab: "Save As Template",
    value: "saveAsTemplate",
    needActions: false,
    fields: [
      {
        label: "Template Image",
        key: "saveAsTemplate",
        type: "saveAsTemplate",
      },
    ],
  },
];

export default gridStyle;
