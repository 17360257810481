import React, { useEffect, useRef, useState } from "react";
import { Node, Path } from "slate";
import { Box } from "@mui/material";
import { Rnd } from "react-rnd";
import Handles from "./TransformHandles";
import { useEditorContext } from "../../hooks/useMouseMove";
import ElementOptions from "./ElementOptions";
import ElementSettings from "./ElementSettings";
import { getClosestDraggable, getParentSectionPath } from "./Utils";
import DragInfo from "./DragInfo";
import GuideLines from "./GuideLines";
import ShadowElement from "./ShadowElement";
import BoundaryLine from "./GuideLines/BoundaryLine";
import ContextMenu from "./ContextMenu";
import VirtualElement from "./VirtualElement";
import { ItemTypes } from "./ElementSettings/settingsConstants";
import { selectText } from "../../utils/helper";
import { removeSign } from "./ElementSettings/OtherSettings";
import useDragging from "../../hooks/useDragging";
import { dragOverOn } from "../../helper/RnD/focusNode";
import { focusSelection, clearSelection } from "../../helper";

const ITEM_TYPES = ["child", "parent-container"];
const EDIT_MODES = ["text", "form", "table"];
const DISABLE_RESIZING = {
  bottom: false,
  top: false,
  left: false,
  right: false,
  topLeft: false,
  topRight: false,
  bottomLeft: false,
  bottomRight: false,
};

const RnD = (props) => {
  const rndRef = useRef(null);
  const {
    id: eId,
    className,
    path,
    children,
    style,
    defaultStyle,
    disableDragging,
    enableResizing,
    actions,
    actionsMap = {},
    type = "child",
    optionsProps = {},
    settingsProps = {},
    onChange = () => {},
    delta = { width: 0, height: 0 },
    handleDragEvent = () => {},
    handleResizeEvent = () => {},
    handleActionClick = () => {},
    readOnly,
    childType = "",
    updated_at = null,
    editor,
    breakpoint = "",
    handleContextMenuClick = () => {},
    itemData = {},
  } = props;
  const {
    isSelectedElement,
    setSelectedElement,
    updateDragging,
    contextMenu,
    setContextMenu,
    theme,
  } = useEditorContext();
  const str_path = path.join("|");
  const selectedElementProps = isSelectedElement(str_path, type);
  const {
    enable,
    cursor,
    path: sp,
    selectedAction,
    elementProps,
  } = selectedElementProps;
  const open = Boolean(enable);
  const currentAction = str_path === sp ? selectedAction : null;
  const positionRef = useRef();
  const { draggingRef, startDragging, stopDragging, setDragOver } =
    useDragging();
  const dragging = draggingRef?.current;
  const { active, id, parentPath } = dragging;
  const dragInfoOpen = id === str_path;
  const [absPosition, setAbsPosition] = useState({});
  const openContextMenu = contextMenu?.path === str_path;
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });
  const parentSectionPath = str_path?.split("|").slice(0, 2).join("_");

  useEffect(() => {
    if (ITEM_TYPES.includes(type)) {
      if (enable === 1) {
        updatePosition();
      } else {
        rndRef.current.posUpdated = false;
        setAbsPosition({});
      }
    }
  }, [enable]);

  const getCurrentEle = () => {
    return positionRef.current?.resizableElement?.current;
  };

  const updatePosition = () => {
    if (rndRef?.current && !rndRef.current.posUpdated) {
      const currentEle = getCurrentEle();
      const parentDom = currentEle?.closest(".freegrid-container-parent");
      const parentRect = parentDom?.getBoundingClientRect();
      const { left, top, width, height } = currentEle.getBoundingClientRect();
      positionRef.current.updatePosition({ x: 0, y: 0 });
      positionRef.current.updateSize({ width: width, height: height });
      rndRef.current.posUpdated = true;
      setAbsPosition({
        position: "absolute",
        left: left - parentRect?.left,
        top: Math.abs(parentRect?.top - top),
        marginTop: 0,
        marginLeft: 0,
      });
    }
  };

  const onClick = (e) => {
    if (readOnly) {
      return;
    }
    if (e?.target?.dataset?.event === "rnd-click") {
      // for context menu
      setContextMenu({ path: null });
      if (e?.preventDefault && e?.stopPropagation && !enable) {
        e.preventDefault();
        e.stopPropagation();
      }

      switch (e.detail) {
        case 1:
          if (!enable) {
            setSelectedElement({
              path: str_path,
              enable: 1,
              cursor: "move",
              anchorEl: rndRef?.current,
            });
          }
          focusSelection(editor, { path });
          break;
        case 2:
          setSelectedElement({
            path: str_path,
            enable: EDIT_MODES.includes(childType) ? 2 : 1,
            cursor: "auto",
            anchorEl: rndRef?.current,
          });
          // for default selection
          if (childType === "text" && ITEM_TYPES.includes(type)) {
            selectText(editor, { path: [...path, 0], cursorOnly: false }, 10);
          }
          break;
        default:
          return;
      }
    }
  };

  const handleAction = (actionType) => {
    try {
      switch (actionType) {
        case 1:
          setSelectedElement({
            path: str_path,
            enable: 1,
            cursor: disableDragging ? "auto" : "move",
          });
          break;
        case 2:
          onClick({
            detail: actionType,
            target: { dataset: { event: "rnd-click" } },
          });
          break;
        case 3:
          clearSelection(editor);
          setSelectedElement({});
          break;
        case "settings":
          setSelectedElement({
            ...selectedElementProps,
            selectedAction: actionType,
            selectedActionPath: path,
          });
          break;
        case "link":
          setSelectedElement({
            ...selectedElementProps,
            selectedAction: actionType,
            selectedActionPath: path,
          });
          break;
        case "signatureSettings":
          setSelectedElement({
            ...selectedElementProps,
            selectedAction: actionType,
            selectedActionPath: path,
          });
          break;
        case "imageFrame":
          setSelectedElement({
            ...selectedElementProps,
            enable: 2,
            selectedAction: actionType,
            selectedActionPath: path,
          });
          break;
        case "removeSign":
          removeSign(editor, sp);
          break;
        case "saveAsTemplate":
          const curPath = type === "parent" ? Path.parent(path) : path;
          const currentNode = Node.get(editor, curPath);
          setSelectedElement({
            ...selectedElementProps,
            selectedAction: actionType,
            selectedActionPath: path,
            elementProps: currentNode,
          });
          break;
        default:
          handleActionClick(actionType, path);
          return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onAfterDrop = (updated_data) => {
    const { updated_at } = updated_data || {};
    setSelectedElement({});
    // to maitain absolute
    rndRef.current.posUpdated = false;
    // need to retain drag option if section changes
    setAbsPosition({});
    updatePosition();
    if (updated_at) {
      // disable dragging
      updateDragging(
        {
          active: false,
          id: null,
          isDragging: false,
          dragOver: null,
        },
        null
      );
    }
    stopDragging();
  };

  const onDragStart = (e) => {
    e.preventDefault();
    if (e?.target?.dataset?.path?.split(",").join("|") === sp) {
      const { left, top, width, height } = e?.target?.getBoundingClientRect();
      const ref = positionRef.current?.resizableElement?.current;
      const updatedPosition = {
        x: e.clientX,
        y: e.clientY,
        strXY: `${e.clientX},${e.clientY}`,
        diffX: parseInt(Math.abs(Math.floor(left - e.clientX))),
        diffY: parseInt(Math.abs(Math.floor(top - e.clientY))),
      };
      const parentPath = getParentSectionPath(
        { ref },
        ".freegrid-container-parent"
      );
      updateDragging({
        ...dragging,
        active: true,
        id: str_path,
        position: {
          ...updatedPosition,
        },
        dimension: { width, height },
        isDragging: 1,
        parentPath,
      });
      setPosition({
        ...updatedPosition,
      });
      startDragging({
        active: true,
        id: str_path,
        position: {
          ...updatedPosition,
        },
        isDragging: 1,
        parentPath,
      });
    }
  };

  const onDrag = (e) => {
    e.preventDefault();
    const lines = getClosestDraggable(
      e.clientX,
      e.clientY,
      `.freegrid-section_${parentSectionPath} .freegrid-container .freegrid-item.inactive-drag`.replace(
        /\|/g,
        "\\|"
      ),
      ".freegrid-item.active-drag:not(.exclude-virtual)"
    );
    setAbsPosition({
      ...absPosition,
      "--zIndex": 2000,
    });
    updateDragging({
      isDragging: 2,
      position: { ...dragging.position, x: e.clientX, y: e.clientY },
      lines: lines,
    });
    setPosition({
      ...dragging.position,
      x: e.clientX,
      y: e.clientY,
      lines: lines,
    });
    const isDragOverOnParent = {};
    let dragOverEle =
      e?.toElement?.dataset?.dragovertype !== "child"
        ? e?.toElement
        : e?.toElement?.closest(".freegrid-container-parent");

    // some cases
    if (dragOverEle?.dataset?.dragovertype === undefined) {
      // means check for parent element
      dragOverEle = e?.toElement?.parentElement;
    }

    if (dragOverEle?.dataset?.dragovertype !== "child") {
      const oldPath = dragging.dragOver;
      isDragOverOnParent.dragOver = dragOverEle?.dataset?.dragoverid;
      isDragOverOnParent.dragOverType = dragOverEle?.dataset?.dragovertype;
      dragOverOn(oldPath, dragOverEle?.dataset?.dragoverid);
    }

    startDragging({
      isDragging: 2,
      ...isDragOverOnParent,
    });
  };

  const onDragStop = (e, d) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      dragging?.isDragging === 2 &&
      dragging?.position?.strXY &&
      dragging?.dragOver
    ) {
      d.x = e.x;
      d.y = e.y;
      d.offsetX = e.offsetX;
      d.offsetY = e.offsetY;
      d.dragOver = dragging?.dragOver;
      d.parentPath = parentPath;
      d.diffX = position?.diffX;
      d.diffY = position?.diffY;
      d.calX = itemData?.left + d.lastX;
      // avoid x, y value replace issue
      const [x, y] = dragging?.position?.strXY.split(",");
      d.startPosition = { ...position, x: parseInt(x), y: parseInt(y) };
      d.endPosition = { x: e.x, y: e.y };
      handleDragEvent("stop", d, onAfterDrop);
      // reset zIndex var on drag stop
      const ud = {
        ...absPosition,
      };
      delete ud["--zIndex"];
      updateDragging(
        {
          dragOver: "",
        },
        null
      );
      setAbsPosition({
        ...ud,
      });
      stopDragging();
    } else {
      // invalid drags
      // found in dev mode and in safari browser
      onAfterDrop(path);
    }
  };

  const onResizeStop = (e, direction, ref, d, position) => {
    e.preventDefault();
    const updatedSize = {
      width: delta?.width + d.width,
      height: delta?.height + d.height,
    };
    onChange({ ...updatedSize });
    handleResizeEvent("stop");
  };

  const onCloseSettings = () => {
    setSelectedElement({
      ...selectedElementProps,
      selectedAction: null,
      selectedActionPath: null,
    });
  };

  const handleContextMenu = (e) => {
    if (readOnly) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    onClick({ detail: 1 });
    setContextMenu({
      x: e.clientX + 2,
      y: e.clientY - 6,
      path: path.join("|"),
    });
  };

  const handleClose = (d) => () => {
    if (d) {
      handleContextMenuClick(d);
    }
    if (d?.autoClose !== false) {
      setContextMenu({ path: null });
    }
  };

  const onMouseOver = (e) => {
    if (type !== "child") {
      e.stopPropagation();
    }
    if (type !== "child") {
      setDragOver({
        dragOver: str_path,
      });
      updateDragging(
        {
          dragOver: str_path,
        },
        str_path
      );
    }
  };

  const getEventProps = () => {
    if (!readOnly) {
      return {
        onResizeStart: (e) => {
          e.preventDefault();
        },
        onDragStart: onDragStart,
        onDrag: onDrag,
        onDragStop: onDragStop,
        onResizeStop: onResizeStop,
        onClick: onClick,
        onContextMenu: handleContextMenu,
        onMouseOver: onMouseOver,
      };
    } else {
      return {};
    }
  };

  return (
    <>
      <Box
        component={Rnd}
        placeholder={ItemTypes[childType] || "Item"}
        id={eId || ""}
        key={eId}
        ref={(c) => {
          positionRef.current = c;
        }}
        className={`${className || ""} ${
          dragInfoOpen ? "active-drag" : "inactive-drag"
        } enable-${enable} type_${childType} section_type_${type || "parent"}`}
        data-path={str_path}
        style={{
          position: "relative",
          outline:
            enable && ITEM_TYPES.includes(type) ? "#2563EB solid 2px" : 0,
          cursor: cursor,
          caretColor: enable === 2 ? "auto" : "transparent",
          userSelect: enable === 2 || readOnly ? "auto" : "none",
          ...style,
          // zIndex: enable === 1 ? 1 : "inherit",
          left: "var(--left)",
          ...absPosition,
        }}
        default={{ ...(defaultStyle || {}) }}
        dragGrid={[2, 2]}
        resizeHandleComponent={enable ? Handles({ type }) : {}}
        disableDragging={readOnly || disableDragging || enable !== 1}
        suppressContentEditableWarning={true}
        enableResizing={
          readOnly
            ? false
            : enable && !active
            ? enableResizing
            : { ...DISABLE_RESIZING }
        }
        {...getEventProps()}
      >
        <div
          id={`opt_ref_${str_path}`}
          style={{
            display: "block",
            position: "absolute",
            left: "-8px",
            top: "-30px",
            minWidth: "calc(100% + 24px)",
            minHeight: "calc(100% + 42px)",
            pointerEvents: "none",
          }}
          ref={rndRef}
          className="options-wrapper"
          contentEditable={false}
        />
        {children}
        {enable !== 2 && type !== "parent" && !readOnly ? (
          <button
            data-event="rnd-click"
            className="editor-blocker"
            data-path={path}
            contentEditable={false}
            data-dragoverid={str_path}
            data-dragovertype={type}
          />
        ) : null}
      </Box>
      {!active && rndRef?.current && open ? (
        <ElementOptions
          id={`opt_ref_${str_path}`}
          open={open}
          anchorEl={rndRef?.current}
          enable={enable}
          handleAction={handleAction}
          actions={actions}
          actionsMap={actionsMap}
          optionsProps={optionsProps}
          selectedAction={currentAction}
          path={str_path}
          theme={theme}
        />
      ) : null}
      <ElementSettings
        open={Boolean(currentAction)}
        currentAction={currentAction}
        anchorEl={rndRef?.current}
        placement={"right-start"}
        onClose={onCloseSettings}
        childType={childType}
        editor={editor}
        path={sp}
        {...settingsProps}
        elementProps={elementProps}
        theme={theme}
      />
      {dragInfoOpen ? (
        <DragInfo
          anchorEl={rndRef?.current}
          open={dragInfoOpen}
          dragging={position}
        />
      ) : null}
      <GuideLines lines={position?.lines || []} />
      {!readOnly && type === "parent" ? <BoundaryLine /> : null}
      <ShadowElement
        type={type}
        enable={enable}
        style={{
          ...style,
          ...defaultStyle,
          left: "var(--left)",
          marginTop: "var(--marginTop)",
          gridArea: "var(--gridArea)",
        }}
      />
      <ContextMenu
        type={type}
        open={openContextMenu}
        contextMenu={contextMenu}
        handleClose={handleClose}
        theme={theme}
      />
      {type === "parent" && breakpoint === "lg" && !readOnly ? (
        <VirtualElement
          parentEle={positionRef?.current?.resizableElement?.current}
          updated_at={updated_at}
          path={str_path}
          editor={editor}
        />
      ) : null}
    </>
  );
};

RnD.defaultProps = {
  disableDragging: false,
  enableResizing: {
    bottom: true,
    bottomLeft: true,
    bottomRight: true,
    left: true,
    right: true,
    top: true,
    topLeft: true,
    topRight: true,
  },
};

export default RnD;
