const DocsIcon = (props) => {
    return (
        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.07324 1V4.14286C8.07324 4.35124 8.15602 4.55109 8.30337 4.69844C8.45072 4.84579 8.65057 4.92857 8.85896 4.92857H12.0018" stroke="url(#paint0_linear_6885_49189)" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.4286 15.1429H2.57143C2.15466 15.1429 1.75496 14.9773 1.46026 14.6826C1.16556 14.3879 1 13.9882 1 13.5714V2.57143C1 2.15466 1.16556 1.75496 1.46026 1.46026C1.75496 1.16556 2.15466 1 2.57143 1H8.07143L12 4.92857V13.5714C12 13.9882 11.8344 14.3879 11.5397 14.6826C11.245 14.9773 10.8453 15.1429 10.4286 15.1429Z" stroke="url(#paint1_linear_6885_49189)" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.14014 5.71289H4.92585" stroke="url(#paint2_linear_6885_49189)" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.14014 8.85742H8.85442" stroke="url(#paint3_linear_6885_49189)" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.14014 12H8.85442" stroke="url(#paint4_linear_6885_49189)" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_6885_49189" x1="10.0375" y1="1" x2="10.0375" y2="4.92857" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2563EB" />
                    <stop offset="1" stopColor="#8360FD" />
                </linearGradient>
                <linearGradient id="paint1_linear_6885_49189" x1="6.5" y1="1" x2="6.5" y2="15.1429" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2563EB" />
                    <stop offset="1" stopColor="#8360FD" />
                </linearGradient>
                <linearGradient id="paint2_linear_6885_49189" x1="4.53299" y1="5.71289" x2="4.53299" y2="6.71289" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2563EB" />
                    <stop offset="1" stopColor="#8360FD" />
                </linearGradient>
                <linearGradient id="paint3_linear_6885_49189" x1="6.49728" y1="8.85742" x2="6.49728" y2="9.85742" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2563EB" />
                    <stop offset="1" stopColor="#8360FD" />
                </linearGradient>
                <linearGradient id="paint4_linear_6885_49189" x1="6.49728" y1="12" x2="6.49728" y2="13" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2563EB" />
                    <stop offset="1" stopColor="#8360FD" />
                </linearGradient>
            </defs>
        </svg>
    )
}


export default DocsIcon;
