import React from "react";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Typography,
  Tooltip,
} from "@mui/material";

import {
  JustifyStartIcon,
  JustifyCenterIcon,
  JustifyEndIcon,
  AlignEndIcon,
  AlignCenterIcon,
  AlignStartIcon,
  DirectionRowIcon,
  DirectionColumIcon,
  DirectionReverseIcon,
} from "../../iconslist";

const Alignment = (props) => {
  const { value, data, onChange } = props;
  const { key } = data;

  const handleChange = (e) => {
    onChange({
      [key]: {
        ...value,
        [e.target.name]: e.target.value,
      },
    });
  };

  const flexDirection = value?.flexDirection || "row";

  return (
    <>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="pos-align-tems">
            <Typography
              variant="body1"
              color={"primary"}
              style={{ fontSize: "14px", fontWeight: 500 }}
            >
              Alignment
            </Typography>
          </FormLabel>
          <div style={{ display: "flex" }}>
            <RadioGroup
              row
              aria-labelledby="pos-jc"
              name="horizantal"
              value={value?.horizantal || "start"}
              onChange={handleChange}
              className="iconRadioButton"
              style={{ display: "flex" }}
            >
              <Tooltip title={"Align Left"}>
                <FormControlLabel
                  value="start"
                  control={<Radio />}
                  label={<JustifyStartIcon />}
                />
              </Tooltip>
              <Tooltip title={"Align Center"}>
                <FormControlLabel
                  value="center"
                  control={<Radio />}
                  label={<JustifyCenterIcon />}
                />
              </Tooltip>
              <Tooltip title={"Align Right"}>
                <FormControlLabel
                  value="end"
                  control={<Radio />}
                  label={<JustifyEndIcon />}
                />
              </Tooltip>
            </RadioGroup>
            <RadioGroup
              row
              aria-labelledby="pos-align-tems"
              name="vertical"
              value={value?.vertical || "start"}
              onChange={handleChange}
              className="iconRadioButton"
              style={{ display: "flex" }}
            >
              <Tooltip title={"Align Top"}>
                <FormControlLabel
                  value="start"
                  control={<Radio />}
                  label={<AlignStartIcon />}
                />
              </Tooltip>
              <Tooltip title={"Align Middle"}>
                <FormControlLabel
                  value="center"
                  control={<Radio />}
                  label={<AlignCenterIcon />}
                />
              </Tooltip>
              <Tooltip title={"Align Bottom"}>
                <FormControlLabel
                  value="end"
                  control={<Radio />}
                  label={<AlignEndIcon />}
                />
              </Tooltip>
            </RadioGroup>
          </div>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="pos-fd">
            <Typography
              variant="body1"
              color={"primary"}
              style={{ fontSize: "14px", fontWeight: 500 }}
            >
              Direction
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="pos-fd"
            name="flexDirection"
            value={flexDirection}
            onChange={handleChange}
            className="iconRadioButton"
          >
            <FormControlLabel
              value="row"
              control={<Radio />}
              label={<DirectionColumIcon />}
            />
            <FormControlLabel
              value="column"
              control={<Radio />}
              label={<DirectionRowIcon />}
            />
            <FormControlLabel
              value="row-reverse"
              control={<Radio />}
              label={<DirectionReverseIcon />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
};

export default Alignment;
