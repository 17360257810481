import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import embedImageStyle from "../../common/StyleBuilder/embedImageStyle";
import embedVideoStyle from "../../common/StyleBuilder/embedVideoStyle";

const EmbedPopup = (props) => {
  const { element, onSave, onClose, customProps, format, onDelete } = props;
  const videoStyles = embedVideoStyle?.filter((f) => {
    if (f?.value === "AspectRatio" && format !== "video") {
      return false;
    }
    return true;
  });
  return (
    <StyleBuilder
      title={
        format === "image"
          ? "Image"
          : format === "video"
          ? "video"
          : format === "calendly"
          ? "calendly"
          : "Embed"
      }
      type={format === "image" ? "embedImageStyle" : "embedVideoStyle"}
      element={element}
      onSave={onSave}
      onClose={onClose}
      renderTabs={format === "image" ? embedImageStyle : videoStyles}
      customProps={customProps}
      onDelete={onDelete}
    />
  );
};

export default EmbedPopup;
