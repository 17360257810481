import { Transforms, Editor, Element, Node, Path } from "slate";
import deserialize from "../helper/deserialize";
import { decodeAndParseBase64 } from "../utils/helper";

const avoidDefaultInsert = ["table", "grid"];

const loopChildren = (children = [], defaultInsert) => {
  if (!children?.length) {
    return defaultInsert;
  }

  for (let child of children) {
    if (avoidDefaultInsert.includes(child?.type)) {
      defaultInsert = false;
      break;
    }

    defaultInsert = loopChildren(child.children, defaultInsert);
  }

  return defaultInsert;
};

const getCurrentElement = (editor) => {
  try {
    if (editor.selection) {
      return Node.parent(editor, editor?.selection?.anchor?.path);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const getCurrentElementText = (editor) => {
  try {
    if (editor.selection) {
      return Editor.string(editor, editor?.selection?.anchor?.path);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const insertAtNextNode = (editor, formattedFragment) => {
  try {
    const { selection } = editor;
    if (selection) {
      const parentPath = Path.parent(editor?.selection?.anchor?.path);

      const nextPath = Path.next(parentPath);

      Transforms.insertNodes(
        editor,
        { type: "paragraph", children: [{ text: "" }] },
        { at: nextPath }
      );

      Transforms.insertFragment(editor, formattedFragment, { at: nextPath });
    }
  } catch (err) {
    console.log(err);
  }
};

const handleInsert = (editor, defaultInsert, fragment = []) => {
  if (
    getCurrentElementText(editor) &&
    fragment.some((f) => f.type === "table")
  ) {
    insertAtNextNode(editor, fragment);
  } else {
    defaultInsert();
  }
};

const formatFragment = {
  "list-item": (fragment) => {
    let refactorFragment = [];

    fragment.forEach((a) => {
      if (a.type === "orderedList") {
        refactorFragment = [...refactorFragment, ...(a.children || [])];
      } else {
        a.type = "list-item";
        refactorFragment.push(a);
      }
    });

    return refactorFragment;
  },
  "check-list-item": (fragment) => {
    return fragment.map((a) => {
      a.type = "check-list-item";
      return a;
    });
  },
};

const getFocusedNode = (editor, nodeType = "") => {
  try {
    const [node] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === nodeType,
    });
    return node;
  } catch (err) {
    console.log(err);
  }
};

const withHtml = (editor) => {
  const { insertData, isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const slateHTML = data?.getData("application/x-slate-fragment");
    const html = data?.getData("text/html");
    const currentEl = getCurrentElement(editor);
    const eltype = currentEl?.type;

    if (slateHTML && !formatFragment[eltype]) {
      const decoded = decodeAndParseBase64(slateHTML);

      const tableNode = getFocusedNode(editor, "table");
      const onlyTextNode = getFocusedNode(editor, "freegrid");

      // paste only text nodes
      if (onlyTextNode) {
        const text = data?.getData("text/plain");
        Transforms.insertText(editor, text);
        return;
      }

      if (tableNode && tableNode[0]) {
        const defaultInsert = loopChildren(decoded, true);

        if (defaultInsert) {
          insertData(data);
          // } else if (editor.isChatEditor) {
          //   // Only convert table to paragraphs if in chat editor mode
          //   const paragraphs = decoded.map(row =>
          //     row.children.map(cell =>
          //       cell.children.map(paragraph =>
          //         paragraph.children.map(textNode => textNode.text).join('')
          //       ).join(' ')
          //     ).join(' ')
          //   ).join('\n'); // Joining with a newline for separate paragraphs

          //   // Insert text as paragraphs
          //   const textNodes = paragraphs.split('\n').map(text => ({
          //     type: 'paragraph',
          //     children: [{ text }]
          //   }));

          //   Transforms.insertNodes(editor, textNodes);
        } else {
          // do not paste table, grid inside table cell
          // only plain text for internal paste
          const text = data?.getData("text/plain");
          Transforms.insertText(editor, text);
        }
      } else {
        handleInsert(editor, () => insertData(data), decoded);
      }
    } else if (html) {
      const parsed = new DOMParser().parseFromString(html, "text/html");

      const isGoogleSheet = parsed.body.querySelector(
        "google-sheets-html-origin"
      );

      if (isGoogleSheet) {
        if (editor.isChatEditor) {
          return;
        }
        const table = parsed.body.querySelector("table");

        const colGrp = table.querySelector("colgroup");
        if (colGrp) {
          colGrp.remove();
        }

        const fragment = deserialize(table);
        Transforms.insertFragment(editor, [fragment]);
        return;
      }

      const fragment = deserialize(parsed.body);

      const formattedFragment = formatFragment[eltype]
        ? formatFragment[eltype](fragment)
        : fragment;

      let is_img_table = false;
      formattedFragment.map((f) => {
        if (f.type === "image" || f?.type?.includes("table")) {
          is_img_table = true;
        }
      });

      if (editor.isChatEditor && is_img_table) {
        return;
      }

      handleInsert(
        editor,
        () => Transforms.insertFragment(editor, formattedFragment),
        formattedFragment
      );

      return;
    } else {
      insertData(data);
    }
  };

  return editor;
};

export default withHtml;
