import React, { useCallback, useEffect, useState } from "react";
import { Popper, Fade, Paper, ClickAwayListener } from "@mui/material";
import { Editor, Range, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import useDrag from "../../hooks/useDrag";
import { TableUtil } from "../../utils/table";
import useWindowResize from "../../hooks/useWindowResize";
import MiniTextFormat from "./MiniTextFormat";
import { useEditorContext } from "../../hooks/useMouseMove";
import usePopupStyles from "../PopupTool/PopupToolStyle";
import useEditorScroll from "../../hooks/useEditorScroll";
import { isCarouselSelected } from "../../helper";

const PopupTool = (props) => {
  const { theme, setIsTextSelected, customProps, editorWrapper } = props;
  const classes = usePopupStyles(theme);
  const { setPopupType, openAI } = useEditorContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const editor = useSlate();
  const { selection } = editor;
  const [event] = useDrag(anchorEl);
  const id = open ? "popup-edit-tool" : "";
  const table = new TableUtil(editor);
  const [size] = useWindowResize();
  const { selectedElement } = useEditorContext();

  const updateAnchorEl = useCallback(() => {
    try {
      const isHavingSelection = selection && !Range.isCollapsed(selection);
      if (isHavingSelection) {
        const domRange = ReactEditor.toDOMRange(editor, editor.selection);

        const editorContainer = document
          .querySelector("#slate-wrapper-scroll-container")
          ?.getBoundingClientRect();

        const rect = domRange.getBoundingClientRect();

        const isOutside =
          rect.bottom < editorContainer.top ||
          rect.top > editorContainer.bottom;

        if (isOutside) {
          rect.y = -500; // hide the popper
        }

        setAnchorEl({
          clientWidth: rect.width,
          clientHeight: rect.height,
          getBoundingClientRect: () => rect,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [selection]);

  useEditorScroll(editorWrapper, updateAnchorEl);

  useEffect(() => {
    const userStoppedSelection = size?.device === "xs" ? true : event === "end"; // for mobile, when user starts the selection, we are gonna show the popup tool

    if (userStoppedSelection && anchorEl && !open) {
      // for table cell selection
      const isCarouselEdit = isCarouselSelected(editor);
      const isCellsSelected = table.isCellSelected(editor.selection) || isCarouselEdit;
      if(isCarouselEdit){
        Transforms.deselect(editor);
      }
      if (!isCellsSelected) {
        setOpen(true);
        setPopupType("textFormat");
        setIsTextSelected(true);
      }
    } else if (!anchorEl) {
      setOpen(false);
      setPopupType("");
      setIsTextSelected(false);
    }
  }, [event, anchorEl]);

  useEffect(() => {
    if (
      !selection ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    ) {
      setAnchorEl(null);
    } else {
      updateAnchorEl();
    }
  }, [selection]);

  useEffect(() => {
    if (selectedElement?.enable === 1) {
      setAnchorEl(null);
    }
  }, [selection, selectedElement?.path, selectedElement?.enable]);

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setPopupType("");
  };

  return open && !openAI ? (
    <ClickAwayListener
      onClickAway={(e) => {
        // close the mini toolbar, if user clicks outside the editor (in Flozy app.)
        if (e.target !== document.body) {
          // e.target returns body, if the user clicks material ui select popup inside the tool bar, on that time, we don't need to close
          handleClose();
        }
      }}
    >
      {size.device === "xs" ? (
        <div className="mobileMiniTextWrapper">
          <MiniTextFormat
            editor={editor}
            classes={classes}
            closeMainPopup={handleClose}
            customProps={customProps}
          />
        </div>
      ) : (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          sx={classes.popupWrapper}
          placement="top-start"
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper
                style={{ borderRadius: "6px", border: "1px solid #8360FD" }}
              >
                <MiniTextFormat
                  editor={editor}
                  classes={classes}
                  closeMainPopup={handleClose}
                  customProps={customProps}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </ClickAwayListener>
  ) : null;
};

export default PopupTool;
