import { useRef, useState } from "react";
import { Editor, Transforms } from "slate";
import { IconButton, Tooltip } from "@mui/material";
import { insertLink } from "../../utils/link";
import Icon from "../../common/Icon";
import { getBlockActive } from "../../utils/SlateUtilityFunctions";
import { getLinkType } from "../../utils/helper";
import LinkSettings from "../../common/LinkSettings";

const LinkButton = (props) => {
  const { editor, customProps } = props;
  const linkInputRef = useRef(null);
  const [showInput, setShowInput] = useState(false);
  const [linkData, setLinkData] = useState({
    name: "",
    url: "",
    showInNewTab: true,
    linkType: "",
  });
  const [selection, setSelection] = useState();
  const { isActive, props: blockProps } = getBlockActive(editor, "link");

  const urlPath = blockProps?.url || blockProps?.href;
  const showInNewTab = blockProps?.showInNewTab || blockProps?.target;
  const { linkType } = linkData || {};

  const handleInsertLink = (linkData) => {
    Transforms.select(editor, selection);
    insertLink(editor, linkData);
    setLinkData({
      name: "",
      url: "",
      showInNewTab: true,
      linkType: "",
    });
    setShowInput(false);
  };

  const toggleLink = () => {
    setSelection(editor.selection);
    if (editor.selection) {
      setLinkData({
        name: Editor.string(editor, editor.selection),
        href: urlPath || "",
        showInNewTab: showInNewTab,
        linkType: blockProps?.linkType,
      });
      setShowInput(true);
    }
  };

  const handleClose = () => {
    setShowInput(false);
  };

  return (
    <div ref={linkInputRef} className="popup-wrapper1">
      <Tooltip title="Link" arrow>
        <IconButton
          className={`${showInput ? "clicked" : ""} ${
            isActive ? "btnActive" : ""
          }`}
          format={"link"}
          onClick={toggleLink}
        >
          <Icon icon="link" />
        </IconButton>
      </Tooltip>

      {showInput ? (
        <LinkSettings
          handleClose={handleClose}
          onSave={({ linkType, navValue, openInNewTab }) => {
            handleInsertLink({
              name: linkData?.name,
              linkType,
              url: navValue,
              showInNewTab: openInNewTab ? "_blank" : "_self",
            });

            handleClose();
          }}
          navType={getLinkType(linkType, urlPath)}
          navValue={urlPath}
          openInNewTab={linkData.showInNewTab === "_blank"}
          customProps={customProps}
        />
      ) : null}
    </div>
  );
};

export default LinkButton;
