import { Transforms, Node, Path } from "slate";
import { ReactEditor } from "slate-react";

export const ROW_HEIGHT = 50;

const MARGIN_OF = {
  xs: 160,
  lg: 490,
};

/**
 * This method will update the grid template rows of parent section based on height
 * @param {*} path
 */
export function updateRows() {}

/**
 * This method will update the grid template columns of parent section based on height
 * @param {*} path
 */
export function updateCols() {}

const handleMoveNode = (editor, path, newPath, { isEmpty }) => {
  try {
    const replaceNode = Node.get(editor, path);
    if (isEmpty) {
      const toPath = [...newPath, 0];
      Transforms.insertNodes(editor, [{ ...replaceNode }], {
        at: toPath,
      });
      Transforms.removeNodes(editor, { at: path });
      return toPath;
    } else {
      Transforms.insertNodes(editor, [{ ...replaceNode }], {
        at: newPath,
      });
      Transforms.removeNodes(editor, { at: path });
      return newPath;
    }
  } catch (err) {
    console.log(err);
    console.log("Drop Node error");
    return null;
  }
};

/**
 * This method will update prop in child node so it will re-render and update the path
 * @param {*} path - contains the parent section path
 */
const reRenderChildNodes = (editor, path) => {
  try {
    const sectionNode = Node.get(editor, path);
    // parent node
    Transforms.setNodes(
      editor,
      { updated_at: new Date().getTime() },
      { at: path }
    );
    sectionNode?.children?.forEach((item, itemIndex) => {
      Transforms.setNodes(
        editor,
        { updated_at: new Date().getTime() },
        { at: [...path, itemIndex] }
      );
    });
  } catch (err) {
    console.log(err);
  }
};

function isContainerElement(editor, moveTopath, props) {
  try {
    const { path, parentPath, dragOver } = props;
    const dragItemPath = path;
    const dragItem = Node.get(editor, dragItemPath);
    let parentNode = null;
    // if freegrid item
    if (dragItem?.type === "freegridItem" || dragItem?.type === "freegridBox") {
      // get parent node
      parentNode = Node.get(editor, Path.parent(dragItemPath));
    }
    const moveToNode = Node.get(editor, moveTopath);
    if (moveToNode.type === "freegridBox") {
      if (parentNode.type === "freegridBox") {
        // same box
        if (parentPath === dragOver) {
          return props.calX;
        } else {
          // for different box
          return parseInt(
            props.x -
              window.innerWidth / 2 +
              MARGIN_OF[props.breakpoint] -
              props.diffX -
              moveToNode.left
          );
        }
      } else {
        return props.calX - moveToNode?.left;
      }
    } else if (moveToNode.type === "freegrid") {
      if (parentNode.type === "freegridBox") {
        return parseInt(
          props.x -
            window.innerWidth / 2 +
            MARGIN_OF[props.breakpoint] -
            props.diffX
        );
      } else {
        return props.calX;
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function onDropItem(props, parentClass) {
  try {
    const {
      editor,
      startPosition,
      endPosition,
      dragOver,
      parentPath,
      path,
      // diffX,
      // x: cx,
      breakpoint,
      // calX,
    } = props;
    const needMove = dragOver !== parentPath;
    const moveTo = dragOver.split("|").map((m) => parseInt(m));
    const from = parentPath.split("|").map((m) => parseInt(m));
    let newPath = [];
    newPath = moveTo;
    const cCalx = isContainerElement(editor, moveTo, props);
    // const posX = parseInt(
    //   cx - window.innerWidth / 2 + MARGIN_OF[breakpoint] - diffX
    // );
    const toSectionNode = Node.get(editor, newPath);
    const addToSectionDOM = ReactEditor.toDOMNode(editor, toSectionNode);
    const rect = addToSectionDOM.getBoundingClientRect();
    const y = endPosition.y - startPosition.diffY - rect.top;

    // Calculate grid position
    const row = Math.floor(y / ROW_HEIGHT) + 1;

    // to calculate difference inside the grid
    const marginTop = Math.abs((row - 1) * ROW_HEIGHT - y);

    // Update grid area
    const gridArea = `${row} / 1 / ${row + 1} / 2`;
    const appenBp = breakpoint === "lg" ? "" : `_${breakpoint}`;
    Transforms.setNodes(
      editor,
      {
        [`gridArea${appenBp}`]: gridArea,
        [`left${appenBp}`]: cCalx,
        [`marginTop${appenBp}`]: marginTop,
        // to avoid auto position / resize of the element
        [`${breakpoint}_updatedOn`]: new Date().getTime(),
      },
      { at: path }
    );

    reRenderChildNodes(editor, from);

    // move the node if section parent changed
    if (needMove) {
      // move the node
      const isEmpty = toSectionNode?.children[0]?.type === undefined;
      if (!isEmpty) {
        newPath = [...newPath, toSectionNode?.children?.length];
      }
      const rPath = handleMoveNode(editor, path, newPath, { isEmpty });
      reRenderChildNodes(editor, moveTo);
      return { updated_at: rPath };
    } else {
      return { updated_at: path };
    }
  } catch (err) {
    console.log(err);
  }
}

export function calculateGridArea(y) {
  const row = Math.floor(y / ROW_HEIGHT) + 1;
  return `${row} / 1 / ${row + 1} / 2`;
}
