import { Editor, Range, Text } from "slate";

const highlightSelection = ([node, path], editor = {}) => {
  if (Text.isText(node) && editor?.selection) {
    const intersection = Range.intersection(
      editor.selection,
      Editor.range(editor, path)
    );

    if (!intersection) {
      return [];
    }

    // Avoid applying highlight if the range only includes line breaks
    const rangeText = Editor.string(editor, intersection);
    if (!rangeText.trim()) {
      return [];
    }

    const range = {
      highlight: true,
      ...intersection,
    };

    return [range];
  }

  return [];
};

export default highlightSelection;
