import { Editor, Transforms, Element as SlateElement } from "slate";
import { Box } from "@mui/material";
import { sizeMap } from "./font";
import Link from "../Elements/Link/Link";
import Image from "../Elements/Embed/Image";
import Video from "../Elements/Embed/Video";
import Equation from "../Elements/Equation/Equation";
import HtmlCode from "../Elements/CodeToText/HtmlCode";
import Table from "../Elements/Table/Table";
import TableRow from "../Elements/Table/TableRow";
import TableCell from "../Elements/Table/TableCell";
import Mentions from "../Elements/Mentions/Mentions";
import Grid from "../Elements/Grid/Grid";
import GridItem from "../Elements/Grid/GridItem";
import Accordion from "../Elements/Accordion/Accordion";
import AccordionSummary from "../Elements/Accordion/AccordionSummary";
import AccordionDetails from "../Elements/Accordion/AccordionDetails";
import Signature from "../Elements/Signature/Signature";
import Signed from "../Elements/Signature/Signed";
import EditorButton from "../Elements/Button/EditorButton";
import Carousel from "../Elements/Carousel/Carousel";
import CarouselItem from "../Elements/Carousel/CarouselItem";
import ImageTextWrapper from "../Elements/ImageText/ImageTextWrapper";
import ImageText from "../Elements/ImageText/ImageText";
import ChipText from "../Elements/ChipText/ChipText";
import DrawerMenu from "../Elements/DrawerMenu/DrawerMenu";
import AppHeader from "../Elements/AppHeader/AppHeader";
import PageSettings from "../Elements/PageSettings/PageSettings";
import Title from "../Elements/Title/title";
import Form from "../Elements/Form/Form";
import FormField from "../Elements/Form/FormField";
import InlineIcon from "../Elements/InlineIcon/InlineIcon";
import SimpleText from "../Elements/SimpleText";
import CheckList from "../Elements/List/CheckList";
import { getTextColor, isEmptyTextNode } from "../helper";
import Attachments from "../Elements/Attachments/Attachments";
import { getBreakPointsValue, groupByBreakpoint } from "../helper/theme";
import Variables from "../Elements/Variables/Variable";
import insertNewLine from "./insertNewLine";
import Divider from "../Elements/Divider/Divider";
import { getBorderColor } from "./helper";
import Code from "../Elements/EmbedScript/Code";
import FreeGrid from "../Elements/FreeGrid/FreeGrid";
import FreeGridItem from "../Elements/FreeGrid/FreeGridItem";
import FreeGridBox from "../Elements/FreeGrid/FreeGridBox";
import SearchAttachment from "../Elements/Search/SearchAttachment";
// import { wrapThemeBreakpoints } from "../Elements/FreeGrid/breakpointConstants";

const alignment = ["alignLeft", "alignRight", "alignCenter", "alignJustify"];
const list_types = ["orderedList", "unorderedList"];

const LIST_FORMAT_TYPE = {
  orderedList: "list-item",
  unorderedList: "list-item",
};

const NEWLINESAFTER = ["headingOne", "headingTwo", "headingThree"];

export const toggleBlock = (editor, format, selection = true, attr = {}) => {
  const isActive = isBlockActive(editor, format);
  const isList = list_types.includes(format);
  const isIndent = alignment.includes(format);
  const isAligned = alignment.some((alignmentType) =>
    isBlockActive(editor, alignmentType)
  );

  /*If the node is already aligned and change in indent is called we should unwrap it first and split the node to prevent
    messy, nested DOM structure and bugs due to that.*/
  if (isAligned && isIndent) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        alignment.includes(
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
        ),
      split: true,
    });
  }

  /* Wraping the nodes for alignment, to allow it to co-exist with other block level operations*/
  if (isIndent) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: [],
    });
    return;
  }
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      list_types.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true,
  });

  // inserting blocks from "/"" commands remove searched word
  if (!selection) {
    Transforms.insertText(editor, "");
  }

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? LIST_FORMAT_TYPE[format] : format,
    ...attr,
  });

  if (isList && !isActive) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: [],
    });
  }

  if (NEWLINESAFTER.indexOf(format) > -1) {
    insertNewLine(editor);
  }
};
export const addMarkData = (editor, data) => {
  try {
    Editor.addMark(editor, data.format, data.value);
  } catch (err) {
    console.log(err);
  }
};
export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
export const isMarkActive = (editor, format) => {
  try {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

export const getBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return { isActive: !!match, props: match && match[0] };
};

export const upateBlockActive = (editor, format, attr = {}) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });
  if (match && match[1] !== undefined) {
    Transforms.setNodes(editor, attr, { at: match[1] });
  }
};

export const activeMark = (editor, format) => {
  const defaultMarkData = {
    color: "#000000",
    bgColor: "#FFFFFF",
    fontSize: "normal",
    fontFamily: "PoppinsRegular",
    fontWeight: "normal",
  };
  try {
    const marks = Editor.marks(editor);
    const defaultValue = defaultMarkData[format];
    return marks?.[format] ?? defaultValue;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getMarked = (leaf, children, theme) => {
  const className = leaf?.doublequote ? "doublequote" : "";

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.strikethrough) {
    children = (
      <span style={{ textDecoration: "line-through" }}>{children}</span>
    );
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.superscript) {
    children = <sup>{children}</sup>;
  }
  if (leaf.subscript) {
    children = <sub>{children}</sub>;
  }
  // cover under single span
  if (
    leaf.color ||
    leaf.bgColor ||
    leaf.fontSize ||
    leaf.fontFamily ||
    leaf.fontWeight ||
    className
  ) {
    const family = leaf?.fontFamily;
    const textStyles = getTextColor(leaf?.color);
    children = (
      <span style={{ background: leaf.bgColor }}>
        <Box
          className={className}
          component={"span"}
          sx={{
            ...groupByBreakpoint(
              {
                fontSize: {
                  lg: sizeMap[leaf.fontSize] || leaf.fontSize,
                  ...getBreakPointsValue(leaf.fontSize, null, "overrideText"),
                },
              },
              theme
            ),
            // ...wrapThemeBreakpoints(
            //   {
            //     lg: sizeMap[leaf.fontSize] || leaf.fontSize,
            //     ...getBreakPointsValue(leaf.fontSize, null, "overrideText"),
            //   },
            //   "fontSize",
            //   theme
            // ),
            // fontSize: {
            //   lg: sizeMap[leaf.fontSize] || leaf.fontSize,
            //   ...getBreakPointsValue(leaf.fontSize, null, "overrideText"),
            // },
            ...textStyles,
            fontFamily: family,
            fontWeight: leaf.fontWeight,
          }}
        >
          {children}
        </Box>
      </span>
    );
  }

  if (leaf.highlight) {
    children = (
      <span style={{ background: "#EAEBFE", color: "inherit" }}>
        {children}
      </span>
    );
  }

  if (leaf.decoration === "link") {
    children = (
      <a
        style={{ cursor: "pointer" }}
        rel="noreferrer"
        target="_blank"
        href={leaf.text}
      >
        {children}
      </a>
    );
  }
  return children;
};

export const getBlock = (props) => {
  const { element, children } = props;
  const attributes = props.attributes ?? {};
  const isEmpty = isEmptyTextNode(element);

  switch (element.type) {
    case "headingOne":
      return (
        <h1
          {...attributes}
          {...element.attr}
          className={`edt-headings content-editable ${isEmpty ? "empty" : ""}`}
          placeholder="Heading 1"
        >
          {children}
        </h1>
      );
    case "headingTwo":
      return (
        <h2
          {...attributes}
          {...element.attr}
          className={`edt-headings content-editable ${isEmpty ? "empty" : ""}`}
          placeholder="Heading 2"
        >
          {children}
        </h2>
      );
    case "headingThree":
      return (
        <h3
          {...attributes}
          {...element.attr}
          className={`edt-headings content-editable ${isEmpty ? "empty" : ""}`}
          placeholder="Heading 3"
        >
          {children}
        </h3>
      );
    case "blockquote":
      return (
        <blockquote
          {...attributes}
          {...element.attr}
          style={{
            // borderColor: element?.color || "transparent",
            ...getBorderColor(element?.color || "transparent", 3),
            background: element?.bgColor || "none",
            padding: `${element?.bgColor ? "16px" : "0px"} 8px`,
            borderRadius: `${element?.color ? "0px" : "12px"} 12px 12px ${
              element?.color ? "0px" : "12px"
            }`,
            margin: `${element?.bgColor ? "16px" : "0px"} 0px`,
            width: element?.bgColor ? "calc(100% - 16px)" : "100%",
            borderWidth: element?.color ? "0px 0px 0px 3px" : "0px",
          }}
        >
          {children}
        </blockquote>
      );
    case "alignLeft":
      return (
        <div {...attributes} {...element.attr}>
          {children}
        </div>
      );
    case "alignCenter":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
          {...attributes}
          {...element.attr}
        >
          {children}
        </div>
      );
    case "alignRight":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            textAlign: "right",
          }}
          {...attributes}
          {...element.attr}
        >
          {children}
        </div>
      );
    case "alignJustify":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "justify",
          }}
          {...attributes}
          {...element.attr}
        >
          {children}
        </div>
      );
    case "list-item":
      const firstChildren = element.children[0] || {};

      return (
        <li
          {...attributes}
          {...element.attr}
          className={`content-editable ${isEmpty ? "empty" : ""}`}
          placeholder="List"
          style={{ color: firstChildren?.color }}
        >
          {children}
        </li>
      );
    case "orderedList":
      return (
        <ol type="1" {...attributes}>
          {children}
        </ol>
      );
    case "unorderedList":
      return <ul {...attributes}>{children}</ul>;
    case "check-list-item":
      return <CheckList {...props} isEmpty={isEmpty} />;
    case "link":
      return <Link {...props} />;
    case "table":
      return <Table {...props} />;
    case "table-head":
      return <thead>{children}</thead>;
    case "table-body":
      return <tbody>{children}</tbody>;
    case "table-row":
      return <TableRow {...props} />;
    case "table-cell":
      return <TableCell {...props} />;
    case "image":
      return <Image {...props} />;
    case "image-text-wrapper":
      return <ImageTextWrapper {...props} />;
    case "image-text":
      return <ImageText {...props} />;
    case "video":
    case "embed":
    case "calendly":
      return <Video {...props} />;
    case "equation":
      return <Equation {...props} />;
    case "htmlCode":
      return <HtmlCode {...props} />;
    case "mention":
      return <Mentions {...props} />;
    case "grid":
      return <Grid {...props} />;
    case "freegrid":
      return <FreeGrid {...props} />;
    case "freegridItem":
      return <FreeGridItem {...props} />;
    case "freegridBox":
      return <FreeGridBox {...props} />;
    case "grid-item":
      return <GridItem {...props} isEmpty={isEmpty} />;
    case "accordion":
      return <Accordion {...props} />;
    case "accordion-summary":
      return <AccordionSummary {...props} />;
    case "accordion-details":
      return <AccordionDetails {...props} />;
    case "signature":
      return <Signature {...props} />;
    case "sign":
      return <Signed {...props} />;
    case "button":
      return <EditorButton {...props} />;
    case "carousel":
      return <Carousel {...props} />;
    case "carousel-item":
      return <CarouselItem {...props} />;
    case "chip-text":
      return <ChipText {...props} />;
    case "drawer":
      return <DrawerMenu {...props} />;
    case "app-header":
      return <AppHeader {...props} />;
    case "page-settings":
      return <PageSettings {...props} />;
    case "title":
      return <Title {...props} />;
    case "form":
      return <Form {...props} />;
    case "form-field":
      return <FormField {...props} />;
    case "icon-text":
      return <InlineIcon {...props} />;
    case "docs":
    case "pdf":
    case "xls":
    case "csv":
      return <Attachments {...props} />;
    case "brains":
      return <SearchAttachment {...props} />
    case "variables":
      return <Variables {...props} />;
    case "topbanner":
      return (
        <span {...props} style={{ display: "none" }}>
          {children}
        </span>
      );
    case "divider":
      return <Divider {...props} />;
    case "embedScript":
      return <Code {...props} />;
    // RnD Focus Node
    case "temp":
      return (
        <span
          {...attributes}
          {...element.attr}
          className="temp-focus-node"
          contentEditable={false}
        >
          {children}
        </span>
      );
    default:
      return <SimpleText {...props} isEmpty={isEmpty} />;
  }
};

export const getQueryStrings = (urlString) => {
  try {
    if (urlString && urlString?.indexOf("/embed") === -1) {
      const newUrl = new URL(urlString);
      var youCode = newUrl.searchParams.get("v");
      if (!youCode) {
        youCode = newUrl.pathname.replace("/", "");
      }
      return `https://www.youtube.com/embed/${youCode}`;
    } else {
      return urlString;
    }
  } catch (err) {
    console.log(err);
    return urlString;
  }
};
