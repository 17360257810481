import { Transforms, Path } from "slate";
import { ReactEditor } from "slate-react";

export const gridItem = (props = {}) => {
  return {
    type: "grid-item",
    grid: 6,
    children: [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ],
    bgColor: "rgba(255, 255, 255, 0)",
    bannerSpacing: {
      left: 8,
      right: 8,
      top: 8,
      bottom: 8,
    },
    ...(props || {}),
  };
};

const updateLastSiblingWidths = (editor, lastChildPath = []) => {
  try {
    Transforms.setNodes(
      editor,
      {
        minWidth: "",
      },
      { at: lastChildPath }
    );
  } catch (err) {
    console.log(err);
  }
};

export const onAddGridItem = ({ editor, element, children, isColumn }) => {
  const currentPath = editor.selection?.anchor?.path;
  const ancestorsPath = Path.ancestors(currentPath, { reverse: true });
  const insertPath = ancestorsPath[1];
  if (insertPath) {
    insertPath[insertPath.length - 1] = element.children.length;
    const lp = ReactEditor.findPath(editor, element);
    // const lastElement = { ...element.children[element.children.length - 1] };
    if (isColumn && children?.length - 1 > 0) {
      updateLastSiblingWidths(editor, [...lp, children.length - 1]);
    }
    Transforms.insertNodes(
      editor,
      gridItem({
        // ...(lastElement || {}),
        minWidth: null,
        bannerSpacing: { top: "8", left: "0", right: "0", bottom: "16" },
        children: [
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ],
      }),
      {
        at: [...lp, children.length],
      }
    );
    // new line
    Transforms.insertNodes(
      editor,
      [{ type: "paragraph", children: [{ text: "" }] }],
      {
        at: [editor.children.length],
      }
    );
  }
};

export const insertGridItem = (editor) => {
  Transforms.insertNodes(editor, { ...gridItem() });
  Transforms.move(editor);
};
