import { Node, Path, Transforms } from "slate";
import default_grid from "../Elements/Grid/templates/default_grid";
import { gridItem } from "./gridItem";
import { customInsertNode } from "./helper";

export const insertPlainGrid = (count) => {
  const size = 12 / count;
  const items = Array.from(Array(count).keys()).map(() =>
    gridItem({
      grid: size,
      cellGHeight: "100%",
      lockSpacing: true,
      bannerSpacing: { top: "0", left: "0", right: "0", bottom: "0" },
      forceFullWidth: true,
      borderWidth: "0px",
    })
  );
  return {
    type: "grid",
    grid: "container",
    children: [...items],
    alignment: { flexDirection: "row" },
    lockSpacing: true,
    bannerSpacing: { top: "0", left: "0", right: "0", bottom: "0" },
    equalItems: true,
    gridSize: 8,
    borderWidth: "0px",
  };
};

export const insertGrid = (editor, item, path) => {
  try {
    const grid = !item ? JSON.parse(default_grid) : item;
    const { selection } = editor;
    customInsertNode(editor, grid, {
      at: path || selection.focus.path,
      select: true,
    });
    // insert new line after grid / column
    const currentNode = Node.get(editor, selection.focus.path);
    if (currentNode?.type === "grid") {
      Transforms.insertNodes(
        editor,
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
        { at: Path.next(Path.parent(selection.focus.path)) }
      );
    }
  } catch (err) {
    console.log(err);
  }
};
