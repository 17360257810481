/* eslint-disable no-unused-vars */
import React, {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import { createEditor, Range, Transforms } from "slate";
import { Slate, Editable, ReactEditor } from "slate-react";
import { useDebouncedCallback } from "use-debounce";
import { getMarked, getBlock } from "./utils/SlateUtilityFunctions";
import CodeToText from "./Elements/CodeToText/CodeToText";
import { draftToSlate } from "./utils/draftToSlate";
import useMentions from "./hooks/useMentions";
import MentionsPopup from "./common/MentionsPopup";
import { RemoteCursorOverlay } from "./RemoteCursorOverlay/Overlay";
import {
  mentionsEvent,
  commands,
  indentation,
  escapeEvent,
  enterEvent,
  upDownArrowKeyEvents,
} from "./utils/events";
import withCommon from "./hooks/withCommon";
import DialogWrapper from "./DialogWrapper";
import { serializeToText } from "./utils/serializeToText";
import { getPageSettings } from "./utils/pageSettings";
import { getThumbnailImage, invertColor } from "./helper";
import PopupTool from "./Toolbar/PopupTool";
import { Box, Typography, useTheme } from "@mui/material";
import Shorthands from "./common/Shorthands";
import MiniToolbar from "./Toolbar/Mini/MiniToolbar";
import { EditorProvider } from "./hooks/useMouseMove";
import TopBanner from "./Elements/TopBanner/TopBanner";
import editorStyles from "./Styles/EditorStyles";
import DragAndDrop from "./common/DnD";
import Section from "./common/Section";
import decorators from "./utils/Decorators";
import { getTRBLBreakPoints } from "./helper/theme";
import {
  handleInsertLastElement,
  isFreeGrid,
  isFreeGridFragment,
  isPageSettings,
  outsideEditorClickLabel,
} from "./utils/helper";
import useWindowResize from "./hooks/useWindowResize";
import PopoverAIInput from "./Elements/AI/PopoverAIInput";
import RnDCopy from "./common/RnD/RnDCopy";
import SwitchViewport from "./common/RnD/SwitchViewport/SwitchViewport";
import { onInsertFragment } from "./utils/RnD/RnDCtrlCmds";
import "./font.css";
import "./Editor.css";
import "animate.css";
import FontLoader from "./common/FontLoader/FontLoader";

const Item = forwardRef(({ children, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      contentEditable={false}
      style={{ background: "rgba(255, 255,255, 0.54)" }}
    >
      {children}
    </div>
  );
});
Item.displayName = "Item";

const Element = (props) => {
  return <Section {...props}>{getBlock(props)}</Section>;
};

const Leaf = ({ attributes, children, leaf }) => {
  const theme = useTheme();
  children = getMarked(leaf, children, theme);
  return <span {...attributes}>{children}</span>;
};

const CommonEditor = forwardRef((props, ref) => {
  const {
    id,
    agency_id,
    site_id,
    page_title,
    content,
    onSave,
    editor: collaborativeEditor,
    readOnly,
    otherProps,
    isIframe,
    theme,
    showViewport = false,
  } = props;
  const editorWrapper = useRef();
  const mentionsRef = useRef();
  const convertedContent = draftToSlate({ data: content });
  const [value, setValue] = useState(convertedContent);
  const [isInteracted, setIsInteracted] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [toolbarShow, setToolbarShow] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [viewport, setViewport] = useState({ w: null, h: null });
  const [isTextSelected, setIsTextSelected] = useState(false);
  const [breakpoint, setBreakpoint] = useState("");
  const debouncedValue = useRef(value);

  const [size] = useWindowResize();
  const {
    needDotsBG,
    footer,
    needLayout = true,
    CHARACTERS = [],
    editorClass,
    fixedWidth = "60%",
    fullWidth = "80%",
    hideTools = [],
  } = otherProps || {};
  const editor = useMemo(() => {
    if (collaborativeEditor) return collaborativeEditor;
    return withCommon(createEditor(), { needLayout });
  }, [collaborativeEditor]);

  const { element: pageSt } = getPageSettings(editor) || {};
  const {
    bannerSpacing,
    pageBgImage,
    pageColor,
    color: pageTextColor,
    pageWidth,
    maxWidth: pageMaxWidth,
  } = pageSt?.pageProps || {
    bannerSpacing: { left: 0, right: 0, top: 0, bottom: 0 },
  };
  const classes = editorStyles({
    padHeight: !fullScreen ? otherProps?.padHeight : 20,
    placeHolderColor: invertColor(pageColor || "#FFF"),
    theme,
  });

  const isMobile = window.matchMedia("(max-width: 899px)")?.matches || false;

  useEffect(() => {
    setValue(draftToSlate({ data: content }));
    if (!isMobile && !ReactEditor.isFocused(editor)) {
      ReactEditor.focus(editor);
    }
  }, [id, content]);

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      const { value: strVal, ...restVal } = getOnSaveData(value);
      debouncedValue.current = value;
      onSave(strVal, restVal);
    },
    // delay in ms
    500
  );

  const getOnSaveData = (val) => {
    const text = serializeToText(val);
    const title = val?.find((f) => f.type === "title");
    return {
      value: JSON.stringify(val),
      text: text,
      title: serializeToText(title?.children) || "Untitled",
    };
  };

  const getPreviewImage = async (needBackground = false, options = {}) => {
    ReactEditor.blur(editor);
    const dom = needBackground
      ? editorWrapper?.current
      : editorWrapper?.current.getElementsByClassName(
          "innert-editor-textbox"
        )[0];
    const c = await getThumbnailImage(dom, {
      ...options,
      proxy: `${otherProps?.PAGES_API_HOST}/page/2canvas`,
    });
    return c;
  };

  // Example of resetting or re-rendering all nodes
  const reRenderAllNodes = () => {
    const newNodes = JSON.parse(JSON.stringify(value));
    setValue(newNodes); // This will re-render the whole document
  };

  const onSwitchBreakpoint = (b) => {
    setBreakpoint(b);
    reRenderAllNodes();
  };

  useImperativeHandle(ref, () => ({
    async getThumbnail(needBackground = false, options = {}) {
      try {
        const c = await getPreviewImage(needBackground, options);
        return c;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    getEditor() {
      return editor;
    },

    getContent() {
      return getOnSaveData(debouncedValue?.current);
    },

    insertFragments(fragments, clearAll = false, rest = {}) {
      try {
        if (!clearAll) {
          if (isFreeGridFragment(fragments)) {
            onInsertFragment({ editor, slateNodes: fragments[0] || fragments });
          } else if (rest?.nextLine) {
            const { anchor } = editor?.selection || {};
            if (anchor?.path !== undefined && anchor?.path[0] !== undefined) {
              editor.insertNode(fragments, { at: [anchor?.path[0] + 1] });
            }
          } else {
            editor.insertNode(fragments);
          }
        } else {
          // loop delete all
          editor.children.forEach(() => {
            Transforms.delete(editor, { at: [0] });
          });

          // reset init
          editor.children = fragments;
        }
      } catch (err) {
        console.log(err);
      }
    },

    toggleFullscreen() {
      setFullScreen(!fullScreen);
    },

    toggleToolbarShow() {
      setToolbarShow(!toolbarShow);
    },

    changeViewport({ w, h }) {
      setViewport({ w, h });
    },

    switchViewport(bp) {
      console.log("switching breakpoint", bp);
      onSwitchBreakpoint(bp);
    },

    undo() {
      editor?.undo();
    },

    redo() {
      editor?.redo();
    },

    getPageSettings: {
      background:
        pageBgImage && pageBgImage !== "none"
          ? `url(${pageBgImage})`
          : pageColor || "",
    },
  }));

  const [htmlAction, setHtmlAction] = useState({
    showInput: false,
    html: "",
    action: "",
    location: "",
  });
  const [mentions, setMentions] = useMentions({
    editor,
    selection: editor?.selection,
  });

  const { search, target, index, type } = mentions;
  const updatedHideTools = !isFreeGrid(content)
    ? [...hideTools, "freegrid"]
    : hideTools;
  const chars =
    type && !isFreeGrid(content)
      ? Shorthands[type]({
          ...mentions,
          CHARACTERS,
          hideTools: updatedHideTools || [],
        })
      : [];

  const handleEditorChange = (newValue) => {
    if (JSON.stringify(newValue) !== JSON.stringify(debouncedValue?.current)) {
      debounced(newValue);
      if (!isInteracted) {
        setIsInteracted(true);
      }
    }
  };

  const onDrawerOpen = (status) => {
    setIsDrawerOpen(status);
  };

  const isReadOnly = readOnly === "readonly";
  const customProps = {
    ...(otherProps || {}),
    readOnly: isReadOnly,
    page_id: id,
    agency_id: agency_id,
    site_id: site_id,
    page_title: page_title,
    isIframe: isIframe,
    onDrawerOpen: onDrawerOpen,
    ICON_API: "https://assets.agenciflow.com",
  };
  const renderElement = useCallback(
    (props) => {
      return <Element {...props} customProps={customProps} theme={theme} />;
    },
    [theme]
  );

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} customProps={customProps} />;
  }, []);

  const handleCodeToText = (partialState) => {
    setHtmlAction((prev) => ({
      ...prev,
      ...partialState,
    }));
  };

  const onKeyDown = useCallback(
    (event) => {
      const isMetaKey =
        event.metaKey && event.keyCode >= 65 && event.keyCode <= 90;
      const isCtrlKey = event.ctrlKey || isMetaKey;
      // for RnD item move front and back
      const isUpandDown = event.metaKey && [38, 40].indexOf(event.keyCode) > -1;
      if (target && chars.length > 0 && !isCtrlKey) {
        mentionsEvent({
          event,
          mentions,
          setMentions,
          chars,
          target,
          editor,
          type,
          mentionsRef,
        });
      } else if (isCtrlKey || isUpandDown) {
        commands({
          event,
          editor,
          needLayout,
        });
      } else if (event.key === "Tab") {
        event.preventDefault();
        indentation({ editor });
      } else if (event.key === "Escape") {
        event.preventDefault();
        escapeEvent({ editor });
      } else if (event.key === "Enter") {
        enterEvent(event, editor, customProps?.isMobile);
      } else if (event.key === "ArrowUp" && otherProps?.tagName !== "Pages") {
        upDownArrowKeyEvents(event, editor);
      } else if (event.key === "ArrowDown" && otherProps?.tagName !== "Pages") {
        upDownArrowKeyEvents(event, editor);
      } else if (event.key === "Backspace") {
        const isPageSettingsNode = isPageSettings(event, editor);
        if (isPageSettingsNode) {
          return;
        }

        const { selection } = editor;
        event.preventDefault();
        if (selection) {
          if (!Range.isCollapsed(selection)) {
            editor.deleteFragment();
          } else {
            editor.deleteBackward({ unit: "character" });
          }
        }
      }
    },
    [chars, target, mentions, setMentions, search, type, mentionsRef]
  );

  const Overlay =
    collaborativeEditor && !isReadOnly ? RemoteCursorOverlay : React.Fragment;

  const dotBg = needDotsBG
    ? {
        background: "white",
        backgroundImage: "radial-gradient(#CCC 2px, transparent 0)",
        backgroundSize: "40px 40px",
        backgroundPosition: "-19px -19px",
      }
    : {};

  const handleScrollStop = useDebouncedCallback(() => {
    editorWrapper?.current?.classList.add("hideScroll");
  }, 200);

  const handleScroll = () => {
    editorWrapper?.current?.classList.remove("hideScroll");
    handleScrollStop();
  };

  const hasTopBanner = () => {
    const tb = editor.children[0];
    return tb?.type === "topbanner" ? tb : null;
  };

  const renderTopBanner = () => {
    const tb = hasTopBanner();
    return tb ? (
      <TopBanner element={tb} editor={editor} customProps={customProps} />
    ) : null;
  };

  const hideMiniToolBar = useMemo(() => {
    if (readOnly) {
      return true;
    }

    if (size?.device === "xs" && isTextSelected) {
      return true;
    }
  }, [readOnly, isTextSelected]);

  const handleFooterClick = () => {
    window.open("https://www.flozy.com", "_blank");
  };

  const editorWrapperStyle = useMemo(() => {
    const style = {
      color: pageTextColor || "",
    };

    if (pageBgImage && pageBgImage !== "none") {
      style.backgroundImage = `url(${pageBgImage})`;
      style.backgroundSize = `cover`;
      style.backgroundRepeat = "repeat";
    } else {
      style.background = pageColor || "";
    }

    return style;
  }, [pageBgImage, pageColor]);

  // const handleContextMenu = (e) => {
  //   if (!readOnly) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //   }
  // };

  const handleCursorScroll = (container) => {
    try {
      if (!customProps?.isMobile) {
        const selection = window?.getSelection();

        if (selection && selection.rangeCount > 0) {
          const cursorPosition = selection
            .getRangeAt(0)
            ?.getBoundingClientRect();
          const containerBottom = container?.getBoundingClientRect()?.bottom;

          if (cursorPosition && cursorPosition.bottom > containerBottom - 250) {
            container?.scrollBy({
              top: 200,
              behavior: "smooth",
            });
          }
        } else {
          console.warn("No valid selection range found");
        }
      }
    } catch (err) {
      console.log("handleCursorScroll", err);
    }
  };

  return (
    <EditorProvider theme={theme} editor={editor}>
      <DialogWrapper
        classes={classes}
        {...props}
        fullScreen={fullScreen}
        footer={footer || ""}
      >
        <Box
          component={"div"}
          className={`et-wrpr stimulate-${breakpoint} ${editorClass || ""} ${
            isIframe ? "iframe-editor" : ""
          }`}
          sx={classes.root}
          style={{
            ...dotBg,
          }}
          data-breakpoint={breakpoint}
          // onContextMenu={handleContextMenu}
        >
          <Slate
            key={id}
            editor={editor}
            initialValue={debouncedValue?.current}
            onChange={handleEditorChange}
          >
            <DragAndDrop>
              <Overlay>
                <Box
                  className={`pc-${editorClass || ""} ${
                    hasTopBanner() ? "has-topbanner" : ""
                  } ${
                    !pageColor ? "no-color" : ""
                  } scrollable-content scrollSmooth`}
                  sx={classes.slateWrapper}
                  id="slate-wrapper-scroll-container"
                  ref={editorWrapper}
                  onClick={(e) => {
                    handleInsertLastElement(e, editor);
                  }}
                  onScroll={handleScroll}
                  style={editorWrapperStyle}
                >
                  <Box
                    component={"div"}
                    className="max-content"
                    data-info={outsideEditorClickLabel}
                  >
                    {renderTopBanner()}
                    <div
                      className="scroll-area"
                      data-info={outsideEditorClickLabel}
                    >
                      <Box
                        component={"div"}
                        className={`editor-wrapper ${
                          pageWidth === "fixed" ? "fixed" : "full"
                        }`}
                        sx={{
                          backgroundColor: "transparent",
                          padding: {
                            ...getTRBLBreakPoints(bannerSpacing),
                          },
                          width:
                            !pageWidth || pageWidth === "fixed"
                              ? fixedWidth
                              : fullWidth,
                          height: viewport.h ? `${viewport.h}px` : `100%`,
                          alignSelf: "center",
                          transformOrigin: "left top",
                          transition: "all 0.3s",
                          minHeight: "87%",
                          maxWidth: pageMaxWidth
                            ? `${parseInt(pageMaxWidth)}px !important`
                            : "auto",
                        }}
                        data-info={outsideEditorClickLabel}
                      >
                        <Editable
                          className="innert-editor-textbox"
                          readOnly={isReadOnly}
                          renderElement={renderElement}
                          renderLeaf={renderLeaf}
                          decorate={(d) => decorators(d, editor)}
                          onKeyDown={onKeyDown}
                          onSelect={() =>
                            handleCursorScroll(editorWrapper.current)
                          }
                        />
                        {!readOnly ? (
                          <MentionsPopup
                            ref={mentionsRef}
                            mentions={mentions}
                            setMentions={setMentions}
                            editor={editor}
                            target={target}
                            index={index}
                            chars={chars}
                            type={type}
                            theme={theme}
                            otherProps={otherProps}
                          />
                        ) : null}
                        <RnDCopy readOnly={readOnly} />
                      </Box>
                    </div>
                    {!hideMiniToolBar ? (
                      <MiniToolbar customProps={customProps} theme={theme} />
                    ) : null}

                    <PopoverAIInput
                      otherProps={otherProps || {}}
                      editorWrapper={editorWrapper}
                    />

                    {footer && (fullScreen || readOnly) && (
                      <Typography
                        sx={{
                          color: "rgb(100, 116, 139)",
                          fontSize: "13px",
                          paddingBottom: hideMiniToolBar ? "0px" : "12px",
                          cursor: "pointer",
                        }}
                        align="center"
                        data-info={outsideEditorClickLabel}
                        onClick={handleFooterClick}
                      >
                        {footer}
                      </Typography>
                    )}
                  </Box>
                </Box>

                {!readOnly ? (
                  <PopupTool
                    onDrawerOpen={onDrawerOpen}
                    theme={theme}
                    setIsTextSelected={setIsTextSelected}
                    customProps={customProps}
                    editorWrapper={editorWrapper}
                  />
                ) : null}
                {!readOnly && showViewport ? (
                  <SwitchViewport
                    breakpoint={breakpoint}
                    onChange={(b) => onSwitchBreakpoint(b)}
                  />
                ) : null}
              </Overlay>
            </DragAndDrop>
            {htmlAction.showInput && (
              <CodeToText {...htmlAction} handleCodeToText={handleCodeToText} />
            )}
            <FontLoader {...props} />
          </Slate>
        </Box>
      </DialogWrapper>
    </EditorProvider>
  );
});
CommonEditor.displayName = "CommonEditor";

CommonEditor.propTypes = {
  /** Id of the content */
  id: PropTypes.string,
  /** Set to true for readonly mode */
  readOnly: PropTypes.oneOf(["readonly", ""]),
  /** It can be a draft js content or a slate js content  */
  content: PropTypes.any,
  /** User details */
  user: PropTypes.object,
  /** API URL base for pages api calls */
  apiHOST: PropTypes.string,
  /** A callback function with updated edited content,
   * this method will get called whenever the content changes happen and wait idle for 500ms
   * uses the debounce mechanism
   */
  onSave: PropTypes.func,
  /**
   * MUI theme object form parent application
   */
  theme: PropTypes.object,
  /**
   * Editor component based props can be passed here
   * @property {bool} isSignerDetailsRequired - To make username and email required in Forms
   */
  otherProps: PropTypes.object,
};

export default CommonEditor;
