const embedVideoStyle = [
  {
    tab: "URL",
    value: "url",
    fields: [
      {
        label: "URL",
        key: "url",
        type: "text",
      },
    ],
  },
  {
    tab: "Aspect Ratio",
    value: "AspectRatio",
    hideOnFGS: true,
    fields: [
      {
        label: "Aspect Ratio",
        key: "aspectRatio",
        type: "textOptions",
        options: [
          {
            text: "16:9 (Default)",
            value: "16 / 9",
          },
          {
            text: "9:16",
            value: "9 / 16",
          },
          {
            text: "Custom",
            value: "custom",
          },
        ],
        renderOption: (option) => {
          return <span>{option.text}</span>;
        },
      },
    ],
  },
  {
    tab: "Position",
    value: "position",
    fields: [
      {
        label: "Hide on Mobile",
        key: "xsHidden",
        type: "selectBox",
        placeholder: "Hide on Mobile",
      },
      {
        label: "Set Postion (Vertical & Horizantal)",
        key: "alignment",
        type: "alignment",
      },
    ],
  },
  {
    tab: "Border",
    value: "border",
    fields: [
      {
        label: "Background Color",
        key: "bgColor",
        type: "color",
      },
      {
        label: "Border Color",
        key: "borderColor",
        type: "color",
      },
      {
        label: "Border",
        key: "borderRadius",
        type: "borderRadius",
      },
      {
        label: "Border Width",
        key: "borderWidth",
        type: "text",
        placeholder: "1px",
      },
      {
        label: "Border Style",
        key: "borderStyle",
        type: "textOptions",
        options: [
          {
            text: "Solid",
            value: "solid",
          },
          {
            text: "Dotted",
            value: "dotted",
          },
          {
            text: "Dashed",
            value: "dashed",
          },
        ],
        renderOption: (option) => {
          return <span>{option.text}</span>;
        },
      },
    ],
  },
  {
    tab: "Banner Spacing",
    value: "bannerSpacing",
    fields: [
      {
        label: "Banner Spacing",
        key: "bannerSpacing",
        type: "bannerSpacing",
      },
    ],
  },
];

export default embedVideoStyle;
