const TopBannerStyles = (theme) => ({
  root: {
    "&:hover": {
      "& .tb-toolbar": {
        display: "block",
      },
    },
  },
  toolbar: {
    position: "absolute",
    width: "auto",
    bottom: "23px",
    right: 0,
    margin: "auto",
    display: "none",
    "& button": {
      background: theme?.palette?.editor?.background,
      border: `1px solid ${theme?.palette?.editor?.popUpBorderColor}`,
      borderRadius: "50%",
      color: "#000",
      marginRight: "8px",
      padding: "4px",
      boxShadow:'0px 0px 32px rgba(255, 255, 255, 0.12)',
      "&:hover": {
        background: theme?.palette?.editor?.background,
        border: `1px solid ${theme?.palette?.editor?.activeColor}`,
        "& svg": {
          fill: theme?.palette?.editor?.activeColor,
        }
      },
      "& svg": {
        width: '20px',
        height: '20px',
        fill: theme?.palette?.editor?.menuOptionTextColor,
      }
    },
  },
});

export default TopBannerStyles;
