const default_grid = [
  {
    type: "grid",
    grid: "container",
    equalItems: true,
    children: [
      {
        type: "grid-item",
        grid: 6,
        children: [{ type: "paragraph", children: [{ text: "" }] }],
        bgColor: "rgba(255, 255, 255, 0)",
        lockSpacing: true,
        bannerSpacing: { top: "8", left: "0", right: "0", bottom: "16" },
        alignment: { horizantal: "center" },
        forceFullWidth: true,
        borderWidth: "0px",
      },
      {
        type: "grid-item",
        grid: 6,
        children: [{ type: "paragraph", children: [{ text: "" }] }],
        bgColor: "rgba(255, 255, 255, 0)",
        lockSpacing: true,
        bannerSpacing: { top: "8", left: "0", right: "0", bottom: "16" },
        forceFullWidth: true,
        borderWidth: "0px",
      },
    ],
    alignment: { flexDirection: "row" },
    lockSpacing: true,
    bannerSpacing: { top: "0", left: "0", right: "0", bottom: "0" },
    borderWidth: "0px",
  },
];

export default JSON.stringify(default_grid);
